import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';

const MQTTDataDisplay = () => {
  const [mqttData, setMqttData] = useState({});
  const [timeData, setTimeData] = useState({});
  const [dayData, setDayData] = useState({});

  useEffect(() => {
    const client = mqtt.connect('wss://nad.sapeli.metrica.cz:9002', {
      username: 'mendry',
      password: 'jJR2tEgIe9U1Vqwb',
    });

    client.on('connect', () => {
      console.log('Připojeno k MQTT serveru');
      client.subscribe('Metrica/Power_Meters');
      client.subscribe('Metrica/Time');
      client.subscribe('Metrica/Days');
    });

    client.on('message', (topic, message) => {
      if (topic === 'Metrica/Power_Meters') {
        const payload = JSON.parse(message.toString());
        setMqttData(payload);
      } else if (topic === 'Metrica/Time') {
        const timePayload = JSON.parse(message.toString());
        setTimeData(timePayload); // Zpracování časových dat
      } else if (topic === 'Metrica/Days') {
        const dayPayload = JSON.parse(message.toString());
        setDayData(dayPayload); // Zpracování časových dat
      }

    });

    return () => client.end();
  }, []);

  return (
    <div>
      {/* Oranžový bar */}
      <div className="header-info-bar">
        <div className="header-info">
          <div className="info-box">
            Aktuální osvit: {mqttData.Sunshine !== undefined ? `${mqttData.Sunshine} W/m²` : 'Načítám...'}
          </div>
          <div className="info-box">
            Teplota: {mqttData.Temperature !== undefined ? `${mqttData.Temperature} °C` : 'Načítám...'}
          </div>
          <div className="info-box">
            Aktuální datum: {dayData.today?.date !== undefined ? `${dayData.today.date}` : 'Načítám...'}
          </div>
          <div className="info-box">
            Aktuální čas: {timeData.time !== undefined ? `${timeData.time}` : 'Načítám...'}
          </div>
          <div className="info-box">V provozu od: 1.7.2024</div>
        </div>
      </div>

      {/* Černý bar s hodnotami */}
      <div className="black-bar">
        <div className="black-info">
          <div className="info-box">
            DODÁVKA Z/DO DS: {mqttData.AC_Power_Main !== undefined ? `${mqttData.AC_Power_Main} kW` : 'Načítám...'}
          </div>
          <div className="info-box">
            VÝKON BESS: {mqttData.AC_Power_Total_EL2 !== undefined ? `${mqttData.AC_Power_Total_EL2} kW` : 'Načítám...'}
          </div>
          <div className="info-box">
            VLASTNÍ VÝROBA: {mqttData.AC_Power_Own !== undefined ? `${mqttData.AC_Power_Own} kW` : 'Načítám...'}
          </div>
          <div className="info-box">
            SPOTŘEBA AREÁLU: {mqttData.AC_Power_Consumption !== undefined ? `${mqttData.AC_Power_Consumption} kW` : 'Načítám...'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MQTTDataDisplay;
