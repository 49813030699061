import React, { useState, useContext, useId } from 'react';
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Line, Legend, Rectangle } from 'recharts';
import { MQTTContext } from './MQTTConn';
import './Electricity.css';

function BarGradient(props) {
  const id = useId();
  const gradientId = `gradient-${id}`;
  const clipPathId = `clipPath-${id}`;

  const bar_max = 100 - Math.round((props.height / props.background.height) * 100);


  return (
    <>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset={`${bar_max}%`} stopColor="#e96323" stopOpacity="1"/>
          <stop offset="100%" stopColor="#e96323" stopOpacity="0.1" />
        </linearGradient>

        <clipPath id={clipPathId}>
          <Rectangle {...props} />
        </clipPath>
      </defs>

      <rect
        x={props.x}
        width={props.width}
        height={props.background?.height}
        fill={`url(#${gradientId})`}
        y={props.background?.y}
        clipPath={`url(#${clipPathId})`
      }
      />
    </>
  );
}

function ActiveBarGradient(props) {
  const id = useId();
  const gradientId = `gradient-${id}`;
  const clipPathId = `clipPath-${id}`;

  const bar_max = 100 - Math.round((props.height / props.background.height) * 100);


  return (
    <>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset={`${bar_max}%`} stopColor="#d51317" stopOpacity="1"/>
          <stop offset="100%" stopColor="#e96323" stopOpacity="0.1" />
        </linearGradient>

        <clipPath id={clipPathId}>
          <Rectangle {...props} />
        </clipPath>
      </defs>

      <rect
        x={props.x}
        width={props.width}
        height={props.background?.height}
        fill={`url(#${gradientId})`}
        y={props.background?.y}
        clipPath={`url(#${clipPathId})`
      }
      />
    </>
  );
}

function formatDate(date) {
  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Měsíc je zero-based, takže přidáme 1
  let year = date.getFullYear();
  return `${day}.${month}.${year} ${date.getHours()}:00`;
}

const CustomTooltip = ({ active, payload, label }) => {

  if (active && payload && payload.length) {
    let selected_time = new Date(label);
    return (
      <div className="electricity-chart-tooltip">
        <p className="label">{ formatDate(selected_time) }</p>
        <p className="data">{payload[0].name.substring(0, payload[0].name.length - 6)}: {payload[0].value} {payload[0].unit}</p>
      </div>
    );
  }

  return null;
};

const Electricity = () => {
  const hourTickFormatter = (tick) => {
    const date = new Date(tick);
  
    if (Math.abs(date.getHours() % 2) === 1)
    {
      return `${date.getHours()}:00`;
    }
    else
    {
      return '';
    }
  };

  const [dsDirection, setDsDirection] = useState(false);
  const [bessDirection, setBessDirection] = useState(false);

  const mqttData = useContext(MQTTContext);

  return (
    <div class="electricity-container">
    <div class="electricity-wrapper">
      <div class="electricity-bar">
        <h2>ELEKTROMĚR - DISTRIBUČNÍ SOUSTAVA</h2>
        <div className="electricity-image">
          <img src="/images/EL.png" alt="Energy Flow"/>
        </div>
      </div>
      <div class="electricity-meter-item">
        <div class="electricity-item">
          <div class="electricity-grid">
            <div className="electricity-img">
              <img src="/images/EL.png" alt="Elektroměr" />
            </div>
            <div class="electricity-section">
              <div class="electricity-direction-change">
                <label className="toggle-switch">
                  <input type="checkbox" checked={dsDirection} onChange={e => setDsDirection(e.target.checked)} />
                  <span className="switch">
                    <div class="left">IMPORT</div>
                    <div class="right">EXPORT</div>
                  </span>
                </label>
              </div>
              <h3>Aktuální přehled</h3>
              {!dsDirection &&
                <>
                  <p><strong>Naměřená data za DNEŠNÍ DEN:</strong> {mqttData.daySumData.Total_Energy_Main_Import_Day !== undefined ? `${mqttData.daySumData.Total_Energy_Main_Import_Day} kWh` : 'Načítám...'}</p>
                  <p><strong>Naměřená data za AKTUÁLNÍ MĚSÍC:</strong> {mqttData.energySumData.Total_Energy_Main_Import_Month !== undefined ? `${mqttData.energySumData.Total_Energy_Main_Import_Month} MWh` : 'Načítám...'}</p>
                  <p><strong>Naměřená data za AKTUÁLNÍ ROK:</strong> {mqttData.energySumData.Total_Energy_Main_Import_Year !== undefined ? `${mqttData.energySumData.Total_Energy_Main_Import_Year} MWh` : 'Načítám...'}</p>
                  <p><strong>Naměřená data od SPUŠTĚNÍ SYSTÉMU:</strong> {mqttData.energySumData.Total_Energy_Main_Import_Lifetime !== undefined ? `${mqttData.energySumData.Total_Energy_Main_Import_Lifetime} MWh` : 'Načítám...'}</p>
                </>
              }
              {dsDirection &&
                <>
                  <p><strong>Naměřená data za DNEŠNÍ DEN:</strong> {mqttData.daySumData.Total_Energy_Main_Export_Day !== undefined ? `${mqttData.daySumData.Total_Energy_Main_Export_Day} kWh` : 'Načítám...'}</p>
                  <p><strong>Naměřená data za AKTUÁLNÍ MĚSÍC:</strong> {mqttData.energySumData.Total_Energy_Main_Export_Month !== undefined ? `${mqttData.energySumData.Total_Energy_Main_Export_Month} MWh` : 'Načítám...'}</p>
                  <p><strong>Naměřená data za AKTUÁLNÍ ROK:</strong> {mqttData.energySumData.Total_Energy_Main_Export_Year !== undefined ? `${mqttData.energySumData.Total_Energy_Main_Export_Year} MWh` : 'Načítám...'}</p>
                  <p><strong>Naměřená data od SPUŠTĚNÍ SYSTÉMU:</strong> {mqttData.energySumData.Total_Energy_Main_Export_Lifetime !== undefined ? `${mqttData.energySumData.Total_Energy_Main_Export_Lifetime} MWh` : 'Načítám...'}</p>
                </>
              }
            </div>
          </div>
          <h3>Graf energií pro DNEŠNÍ DEN</h3>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              width={500}
              height={300}
              data={mqttData.dayEnergyData.energy !== undefined ? mqttData.dayEnergyData.energy: []}
              margin={{
                top: 25,
                right: 20,
                left: 30,
                bottom: 5,
              }}
            >
              
              <CartesianGrid strokeDasharray="1 0" vertical={false} opacity={0.5}/>
              <XAxis dataKey="name" tickFormatter={hourTickFormatter}/>
              <YAxis unit=" kWh"/>
              <Tooltip content={<CustomTooltip />}/>
              <Bar unit="kWh" name={!dsDirection ? "Import energie v kWh" : "Export energie v kWh"} type="monotone" dataKey={!dsDirection ? "Energy_Main_Import" : "Energy_Main_Export"} fill="#e96323" strokeWidth={3} animationDuration={2000} animationEasing="linear" shape={<BarGradient />} activeBar={<ActiveBarGradient />} />
              <Legend />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      </div>
      
      <div class="electricity-wrapper">
      <div class="electricity-bar">
          <h2>ELEKTROMĚR - BATERIOVÝ SYSTÉM</h2>
          <div className="electricity-image">
            <img src="/images/EL.png" alt="Energy Flow"/>
          </div>
        </div>
      <div class="electricity-meter-item">
        <div class="electricity-item">
          <div class="electricity-grid">
            <div className="electricity-img">
              <img src="/images/EL.png" alt="Elektroměr" />
            </div>
            <div class="electricity-section">
              <div class="electricity-direction-change">
                <label className="toggle-switch">
                  <input type="checkbox" checked={bessDirection} onChange={e => setBessDirection(e.target.checked)} />
                  <span className="switch">
                    <div class="left">IMPORT</div>
                    <div class="right">EXPORT</div>
                  </span>
                </label>
              </div>
              <h3>Aktuální přehled</h3>
              {!bessDirection &&
              <>
                <p><strong>Naměřená data za DNEŠNÍ DEN:</strong> {mqttData.daySumData.Total_Energy_BESS_Charge_Day !== undefined ? `${mqttData.daySumData.Total_Energy_BESS_Charge_Day} kWh` : 'Načítám...'}</p>
                <p><strong>Naměřená data za AKTUÁLNÍ MĚSÍC:</strong> {mqttData.energySumData.Total_Energy_BESS_Charge_Month !== undefined ? `${mqttData.energySumData.Total_Energy_BESS_Charge_Month} MWh` : 'Načítám...'}</p>
                <p><strong>Naměřená data za AKTUÁLNÍ ROK:</strong> {mqttData.energySumData.Total_Energy_BESS_Charge_Year !== undefined ? `${mqttData.energySumData.Total_Energy_BESS_Charge_Year} MWh` : 'Načítám...'}</p>
                <p><strong>Naměřená data od SPUŠTĚNÍ SYSTÉMU:</strong> {mqttData.energySumData.Total_Energy_BESS_Charge_Lifetime !== undefined ? `${mqttData.energySumData.Total_Energy_BESS_Charge_Lifetime} MWh` : 'Načítám...'}</p>
              </>
              }
              {bessDirection &&
              <>
                <p><strong>Naměřená data za DNEŠNÍ DEN:</strong> {mqttData.daySumData.Total_Energy_BESS_Discharge_Day !== undefined ? `${mqttData.daySumData.Total_Energy_BESS_Discharge_Day} kWh` : 'Načítám...'}</p>
                <p><strong>Naměřená data za AKTUÁLNÍ MĚSÍC:</strong> {mqttData.energySumData.Total_Energy_BESS_Discharge_Month !== undefined ? `${mqttData.energySumData.Total_Energy_BESS_Discharge_Month} MWh` : 'Načítám...'}</p>
                <p><strong>Naměřená data za AKTUÁLNÍ ROK:</strong> {mqttData.energySumData.Total_Energy_BESS_Discharge_Year !== undefined ? `${mqttData.energySumData.Total_Energy_BESS_Discharge_Year} MWh` : 'Načítám...'}</p>
                <p><strong>Naměřená data od SPUŠTĚNÍ SYSTÉMU:</strong> {mqttData.energySumData.Total_Energy_BESS_Discharge_Lifetime !== undefined ? `${mqttData.energySumData.Total_Energy_BESS_Discharge_Lifetime} MWh` : 'Načítám...'}</p>
              </>
              }
            </div>
          </div>
          <h3>Graf energií pro DNEŠNÍ DEN</h3>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              width={500}
              height={300}
              data={mqttData.dayEnergyData.energy !== undefined ? mqttData.dayEnergyData.energy: []}
              margin={{
                top: 25,
                right: 20,
                left: 30,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="1 0" vertical={false} opacity={0.5}/>
              <XAxis dataKey="name" tickFormatter={hourTickFormatter}/>
              <YAxis unit=" kWh"/>
              <Tooltip content={<CustomTooltip />}/>
              <Bar unit="kWh" name={!bessDirection ? "Import energie v kWh" : "Export energie v kWh"} type="monotone" dataKey={!bessDirection ? "Energy_BESS_Charge" : "Energy_BESS_Discharge"} fill="#e96323" strokeWidth={3} animationDuration={2000} animationEasing="linear" shape={<BarGradient />} activeBar={<ActiveBarGradient />} />
              <Legend />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      </div>

      <div class="electricity-wrapper">
        <div class="electricity-bar">
          <h2>ELEKTROMĚR - ROZPADOVÉ MÍSTO 1</h2>
          <div className="electricity-image">
            <img src="/images/EL.png" alt="Energy Flow"/>
          </div>
        </div>
      <div class="electricity-meter-item">
        <div class="electricity-item">
          <div class="electricity-grid">
            <div className="electricity-img">
              <img src="/images/EL.png" alt="Elektroměr" />
            </div>
            <div class="electricity-section">
              <h3>Aktuální přehled</h3>
              <p><strong>Naměřená data za DNEŠNÍ DEN:</strong> {mqttData.daySumData.Total_Energy_FVE_EL1 !== undefined ? `${mqttData.daySumData.Total_Energy_FVE_EL1} kWh` : 'Načítám...'}</p>
              <p><strong>Naměřená data za AKTUÁLNÍ MĚSÍC:</strong> {mqttData.energySumData.Total_Energy_FVE_EL1_Month !== undefined ? `${mqttData.energySumData.Total_Energy_FVE_EL1_Month} MWh` : 'Načítám...'}</p>
              <p><strong>Naměřená data za AKTUÁLNÍ ROK:</strong> {mqttData.energySumData.Total_Energy_FVE_EL1_Year !== undefined ? `${mqttData.energySumData.Total_Energy_FVE_EL1_Year} MWh` : 'Načítám...'}</p>
              <p><strong>Naměřená data od SPUŠTĚNÍ SYSTÉMU:</strong> {mqttData.energySumData.Total_Energy_FVE_EL1_Lifetime !== undefined ? `${mqttData.energySumData.Total_Energy_FVE_EL1_Lifetime} MWh` : 'Načítám...'}</p>
            </div>
          </div>
          <h3>Graf energií pro DNEŠNÍ DEN</h3>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              width={500}
              height={300}
              data={mqttData.dayEnergyData.energy !== undefined ? mqttData.dayEnergyData.energy: []}
              margin={{
                top: 25,
                right: 20,
                left: 30,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="1 0" vertical={false} opacity={0.5}/>
              <XAxis dataKey="name" tickFormatter={hourTickFormatter}/>
              <YAxis unit=" kWh"/>
              <Tooltip content={<CustomTooltip />}/>
              <Bar unit="kWh" name="Import energie v kWh" type="monotone" dataKey="Energy_FVE_EL1" fill="#e96323" strokeWidth={3} animationDuration={2000} animationEasing="linear" shape={<BarGradient />} activeBar={<ActiveBarGradient />} />
              <Legend />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      </div>

      <div class="electricity-wrapper">
      <div class="electricity-bar">
        <h2>ELEKTROMĚR - ROZPADOVÉ MÍSTO 2</h2>
        <div className="electricity-image">
          <img src="/images/EL.png" alt="Energy Flow"/>
        </div>
      </div>
      <div class="electricity-meter-item">
        <div class="electricity-item">
          <div class="electricity-grid">
            <div className="electricity-img">
              <img src="/images/EL.png" alt="Elektroměr" />
            </div>
            <div class="electricity-section">
              <h3>Aktuální přehled</h3>
              <p><strong>Naměřená data za DNEŠNÍ DEN:</strong> {mqttData.daySumData.Total_Energy_FVE_EL2 !== undefined ? `${mqttData.daySumData.Total_Energy_FVE_EL2} kWh` : 'Načítám...'}</p>
              <p><strong>Naměřená data za AKTUÁLNÍ MĚSÍC:</strong> {mqttData.energySumData.Total_Energy_FVE_EL2_Month !== undefined ? `${mqttData.energySumData.Total_Energy_FVE_EL2_Month} MWh` : 'Načítám...'}</p>
              <p><strong>Naměřená data za AKTUÁLNÍ ROK:</strong> {mqttData.energySumData.Total_Energy_FVE_EL2_Year !== undefined ? `${mqttData.energySumData.Total_Energy_FVE_EL2_Year} MWh` : 'Načítám...'}</p>
              <p><strong>Naměřená data od SPUŠTĚNÍ SYSTÉMU:</strong> {mqttData.energySumData.Total_Energy_FVE_EL2_Lifetime !== undefined ? `${mqttData.energySumData.Total_Energy_FVE_EL2_Lifetime} MWh` : 'Načítám...'}</p>
            </div>
          </div>
          <h3>Graf energií pro DNEŠNÍ DEN</h3>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              width={500}
              height={300}
              data={mqttData.dayEnergyData.energy !== undefined ? mqttData.dayEnergyData.energy: []}
              margin={{
                top: 25,
                right: 20,
                left: 30,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="1 0" vertical={false} opacity={0.5}/>
              <XAxis dataKey="name" tickFormatter={hourTickFormatter}/>
              <YAxis unit=" kWh"/>
              <Tooltip content={<CustomTooltip />}/>
              <Bar unit="kWh" name="Import energie v kWh" type="monotone" dataKey="Energy_FVE_EL2" fill="#e96323" strokeWidth={3} animationDuration={2000} animationEasing="linear" shape={<BarGradient />} activeBar={<ActiveBarGradient />} />
              <Legend />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Electricity;
