import React from 'react';
import './LoginPage2.css'; // Import CSS souboru

const LoginPage2 = () => {
  return (
    <div className="login-page">
      <div className="login-page-container">
        <h1 className="login-title">PŘIHLAŠTE SE DO VAŠEHO SYSTÉMU</h1>
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          alt="Metrica Logo"
          className="login-logo"
        />
        <p className="login-description">
          S Metrikou získáte přístup k datům, která vás posunou blíže k energetické soběstačnosti a udržitelnosti. Věříme, že díky správně řízené energii může každý přispět k lepší budoucnosti.
        </p>
        <div className="store-buttons">
          <button className="app-store-button">
            <img 
              src={`${process.env.PUBLIC_URL}/images/apple.png`} 
              alt="App Store Icon" 
              className="store-icon"
            />
            <div className="text-container">
              <span className="available-text">Available on the</span>
              <span className="store-text">APP STORE</span>
            </div>
          </button>
          <button className="google-play-button">
            <img 
              src={`${process.env.PUBLIC_URL}/images/google-play.png`} 
              alt="Google Play Icon" 
              className="store-icon"
            />
            <div className="text-container">
              <span className="available-text">Available on the</span>
              <span className="store-text">GOOGLE PLAY</span>
            </div>
          </button>
        </div>
      </div>
      <div className="login-form-container">
        <form className="login-form">
          <select className="login-input">
            <option>Vyberte instalaci</option>
          </select>
          <input type="text" className="login-input" placeholder="Uživatelské jméno" />
          <input type="password" className="login-input" placeholder="Heslo" />
          <div className="login-options">
            <button type="button" className="forgot-password">Zapomenuté heslo</button>
            <button type="submit" className="login-button">Přihlásit se</button>
          </div>
        </form>
      </div>
      <footer className="login-footer">
        <p>Powered by YOUNG4ENERGY s.r.o. | O METRICA 2024 | Verze aplikace: 1.091</p>
      </footer>
    </div>
  );
};

export default LoginPage2;
