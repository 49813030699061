import React, { useContext } from 'react';
import './FVEOverview.css';
import { MQTTContext } from './MQTTConn';

function SE100KInverter(props) {
  const powerLevel_INV = props.INV.P_Total || 0;
  const maxPower_INV = 100;
  const powerLevelWidth_INV = `${(powerLevel_INV / maxPower_INV) * 100}%`;

  return (
    <div className="inverter-wrapper">
      {/* Horní lišta střídače */}
      <div className="inverter-top-bar">
        <h2>STŘÍDAČ Č.&nbsp;{props.number} - SOLAREDGE SE100K</h2>
      </div>

      {/* Obrázek střídače */}
      <div className="inventer-top-bar-image">
        <img src="/images/inventer.png" alt="Inventer" />
      </div>

      {/* Blok střídače */}
      <div className="inverter-block">
        <div className="inverter-content">
          {/* Stejnosměrná část střídače */}
          <div className="dc-section">
            <h3>STEJNOSMĚRNÁ ČÁST STŘÍDAČE:</h3>
            <div className="parameter-table dc-table">
              <div className="parameter-cell parameter-header parameter-title"></div>
              <div className="parameter-cell parameter-header">Hodnota</div>
              <div className="parameter-cell parameter-title">Činný výkon:</div>
              <div className="parameter-cell">xx kW</div>
              <div className="parameter-cell parameter-title">Proud AC:</div>
              <div className="parameter-cell">xx A</div>
              <div className="parameter-cell parameter-title">Účiník:</div>
              <div className="parameter-cell">xx </div>
              <div className="parameter-cell parameter-title">Napětí AC:</div>
              <div className="parameter-cell">xx V</div>
            </div>
          </div>

          {/* Obrázek střídače a šipky */}
          <div className="inverter-image">
            <img src="/images/se100k.png" alt="SE100k" />
            {powerLevel_INV > 0 && (
              <>
                {/* Kontejner pro levé šipky mezi DC boxem a střídačem */}
                <div className="arrow-container-left">
                  <svg
                    className="arrow-svg"
                    viewBox="0 0 150 60"
                    width="150"
                    height="60"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    <path d="M40 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    <path d="M70 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                  </svg>
                </div>

                {/* Kontejner pro pravé šipky mezi střídačem a AC boxem */}
                <div className="arrow-container-right">
                  <svg
                    className="arrow-svg"
                    viewBox="0 0 150 60"
                    width="150"
                    height="60"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    <path d="M40 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    <path d="M70 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                  </svg>
                </div>
              </>
            )}
            {/* Kontejner pro ukazatel výkonu */}
            <div className="power-container">
              <div className="power-info">AKTUÁLNÍ VÝKON:</div>
              <div className="power-bar">
                <div className="power-level" style={{ width: powerLevelWidth_INV }}></div>
                <div className="power-value">{powerLevel_INV} kW</div> {/* Nový element */}
              </div>
              <div className="power-scale">
                <span>0 kW</span>
                <span>50 kW</span>
                <span>100 kW</span>
              </div>
            </div>
          </div>

          {/* Střídavá část střídače vpravo */}
          <div className="ac-section">
            <h3>STŘÍDAVÁ ČÁST STŘÍDAČE:</h3>
            <div className="parameter-table ac-table">
              <div className="parameter-cell parameter-header parameter-title"></div>
              <div className="parameter-cell parameter-header">1.FÁZE</div>
              <div className="parameter-cell parameter-header">2.FÁZE</div>
              <div className="parameter-cell parameter-header">3.FÁZE</div>
              <div className="parameter-cell parameter-title">Činný výkon:</div>
              <div className="parameter-cell">
                {props.INV.P_L1 !== undefined ? `${props.INV.P_L1} kW` : 'Načítám...'}
              </div>
              <div className="parameter-cell">
                {props.INV.P_L2 !== undefined ? `${props.INV.P_L2} kW` : 'Načítám...'}
              </div>
              <div className="parameter-cell">
                {props.INV.P_L3 !== undefined ? `${props.INV.P_L3} kW` : 'Načítám...'}
              </div>
              <div className="parameter-cell parameter-title">Proud AC:</div>
              <div className="parameter-cell">
                {props.INV.I_L1 !== undefined ? `${props.INV.I_L1} A` : 'Načítám...'}
              </div>
              <div className="parameter-cell">
                {props.INV.I_L2 !== undefined ? `${props.INV.I_L2} A` : 'Načítám...'}
              </div>
              <div className="parameter-cell">
                {props.INV.I_L3 !== undefined ? `${props.INV.I_L3} A` : 'Načítám...'}
              </div>
              <div className="parameter-cell parameter-title">Účiník:</div>
              <div className="parameter-cell parameter-merged">
                {props.INV.PF !== undefined ? `${props.INV.PF}` : 'Načítám...'}
              </div>
              <div className="parameter-cell parameter-title">Napětí AC:</div>
              <div className="parameter-cell">
                {props.INV.U_L1N !== undefined ? `${props.INV.U_L1N} V` : 'Načítám...'}
              </div>
              <div className="parameter-cell">
                {props.INV.U_L2N !== undefined ? `${props.INV.U_L2N} V` : 'Načítám...'}
              </div>
              <div className="parameter-cell">
                {props.INV.U_L3N !== undefined ? `${props.INV.U_L3N} V` : 'Načítám...'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FVEOverview = () => {
  const mqttData = useContext(MQTTContext);

  // Kontrola, zda mqttData a INVData existují
  if (!mqttData || !mqttData.INVData) {
    return <div>Načítám data...</div>;
  }

  // Kontrola, zda INV1 nebo INV2 existují
  if (!mqttData.INVData.INV1 && !mqttData.INVData.INV2 && !mqttData.INVData.INV3 && !mqttData.INVData.INV4 && !mqttData.INVData.INV5 && !mqttData.INVData.INV6  && !mqttData.INVData.INV7 && !mqttData.INVData.INV8 && !mqttData.INVData.INV9) {
    return <div>Načítám data střídačů...</div>;
  }


  return (
    <div className="fve-overview">
      {/* Přehled Střídače INV1 */}
      <SE100KInverter INV={mqttData.INVData.INV1} number={1}/>
      <SE100KInverter INV={mqttData.INVData.INV2} number={2}/>
      <SE100KInverter INV={mqttData.INVData.INV3} number={3}/>
      <SE100KInverter INV={mqttData.INVData.INV4} number={4}/>
      <SE100KInverter INV={mqttData.INVData.INV5} number={5}/>
      <SE100KInverter INV={mqttData.INVData.INV6} number={6}/>
      <SE100KInverter INV={mqttData.INVData.INV7} number={7}/>
      <SE100KInverter INV={mqttData.INVData.INV8} number={8}/>
      <SE100KInverter INV={mqttData.INVData.INV9} number={9}/>
    </div>
  );
};

export default FVEOverview;
