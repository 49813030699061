// src/components/BESSOverview.js
import React, { useContext } from 'react';
import { MQTTContext } from './MQTTConn';
import { motion } from 'framer-motion';
import './BESSOverview.css'; // Ujistěte se, že cesta je správná

export default function BESSOverview() {
  const mqttData = useContext(MQTTContext);

  // Calculate the SoC percentage for the power bar
  const bessEnergyValue =
    mqttData.bessData?.BESS_Energy !== undefined ? parseFloat(mqttData.bessData.BESS_Energy) : null;
  const socValue =
    mqttData.bessData?.SoC !== undefined ? parseFloat(mqttData.bessData.SoC) : null;
  const socPercentage =
    socValue !== null ? Math.min(Math.max(socValue, 0), 100) : 0; // Clamp between 0 and 100

  // Funkce pro interpolaci mezi dvěma barvami
  function interpolateColor(color1, color2, factor) {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
  }

  // Funkce pro získání barvy na základě socPercentage
  function getColor(socPercentage) {
    let color = '';
    if (socPercentage <= 50) {
      // Interpolace mezi červenou a žlutou
      const ratio = socPercentage / 50; // Hodnota od 0 do 1
      const color1 = [213, 19, 23]; // Červená (#D51317)
      const color2 = [243, 156, 18]; // Žlutá (#f39c12)
      const interpolated = interpolateColor(color1, color2, ratio);
      color = `rgb(${interpolated[0]}, ${interpolated[1]}, ${interpolated[2]})`;
    } else {
      // Interpolace mezi žlutou a zelenou
      const ratio = (socPercentage - 50) / 50; // Hodnota od 0 do 1
      const color1 = [243, 156, 18]; // Žlutá (#f39c12)
      const color2 = [125, 186, 0]; // Zelená (#7dba00)
      const interpolated = interpolateColor(color1, color2, ratio);
      color = `rgb(${interpolated[0]}, ${interpolated[1]}, ${interpolated[2]})`;
    }
    return color;
  }

  // Vypočítaná barva pro power-level
  const powerLevelColor = getColor(socPercentage);

  // Logika pro kontrolu směru toku energie
  const isCharging = mqttData.bessData.Total_Power_BESS_Charge > 0;
  const isDischarging = -mqttData.bessData.Total_Power_BESS_Discharge < 0;

  // Získání statusu BESS
  const statusBESS = mqttData.bessData?.Status_BESS || "";

  // Směr toku energie - logika
  let energyFlowDirection = "";

  if (statusBESS === "Vypnuto") {
    energyFlowDirection = "idle"; // Pokud je BESS vypnutý
  } else if (isCharging) {
    energyFlowDirection = "charging"; // Nabíjení - tok energie do BESS
  } else if (isDischarging) {
    energyFlowDirection = "discharging"; // Vybíjení - tok energie z BESS
  } else {
    energyFlowDirection = "idle"; // Žádný tok energie
  }


  // Definice animace šipky
  const arrowAnimation = {
    charging: {
      opacity: [0, 1, 0],
      x: ["-30%", "60%", "150%"], // Pohyb vlevo od -10% do -110%
      transition: {
        duration: 4,
        ease: 'easeInOut',
        repeat: Infinity,
        times: [0, 0.5, 1], // Klíčové snímky v 0%, 50% a 100% času
      },
    },
    discharging: {
      opacity: [0, 1, 0],
      x: ["50%", "-40%", "-130%"], // Pohyb vpravo od 110% do -10%
      transition: {
        duration: 4,
        ease: 'easeInOut',
        repeat: Infinity,
        times: [0, 0.5, 1], // Klíčové snímky v 0%, 50% a 100% času
      },
    },
    idle: {
      opacity: 0,
    },
  };

  return (
    <div className="bess-page">
      {/* Oranžový bar nad .bess-top */}
      <div className="top-bess-wrapper">
      <div className="bess-top-bar">
        <h2>AKTUÁLNÍ STAV BATERIOVÉHO SYSTÉMU</h2>
      </div>
      <div className="bess-top-bar-image">
        <img src="/images/BESS.png" alt="BESS"/>
        </div>

      <div className="bess-top">
        {/* Kontejner pro obrázky a šipky */}
        <div className="bess-images-container">
          {/* Kontejner pro první obrázek */}
          <div className="bess-image-wrapper-bess">
            <img
              src="/images/centralni_objekt.png"
              alt="Centrální objekt"
              className="bess-image"
            />
          </div>

          {/* Kontejner pro animované šipky */}
          <div className="bess-arrow-container">
            <motion.svg
              className={`bess-arrow-svg ${energyFlowDirection}`}
              animate={energyFlowDirection}
              variants={arrowAnimation}
              viewBox="0 0 120 40" // Zvýšení velikosti zobrazení
              width="150" // Zvětšení šířky SVG
              height="100" // Zvětšení výšky SVG
              xmlns="http://www.w3.org/2000/svg"
            >
              {energyFlowDirection==='charging' && 
                <g>
                  <path d="M10 10 l20 20 -20 20" fill="none" stroke="#E96323" strokeWidth="7" />
                  <path d="M50 10 l20 20 -20 20" fill="none" stroke="#E96323" strokeWidth="7" />
                  <path d="M90 10 l20 20 -20 20" fill="none" stroke="#E96323" strokeWidth="7" />
                </g>
              }
              {energyFlowDirection==='discharging' && 
                <g>
                  <path d="M25 10 l-20 20 20 20" fill="none" stroke="#E96323" strokeWidth="7" />
                  <path d="M65 10 l-20 20 20 20" fill="none" stroke="#E96323" strokeWidth="7" />
                  <path d="M105 10 l-20 20 20 20" fill="none" stroke="#E96323" strokeWidth="7" />
                </g>
              }
            </motion.svg>
          </div>

          {/* Kontejner pro druhý obrázek */}
          <div className="bess-image-wrapper-bess">
            <img
              src="/images/mtu-QL.png"
              alt="Bateriový systém"
              className="bess-image-bess"
            />
          </div>
        </div>

        {/* Power Bar Container */}
        <div className="bess-power-container">
          <div className="power-info">NABITÍ BESS:</div>
          <div className="power-bar">
            <div
              className="power-level"
              style={{
                width: `${socPercentage}%`,
                backgroundColor: powerLevelColor,
              }}
            ></div>
            <div className="power-value">
              {socValue !== null ? `${socValue} %` : 'Načítám...'}
              {bessEnergyValue !== null ? ` (${bessEnergyValue} kWh)` : ''}
            </div>
          </div>
          <div className="power-scale">
            <span>0 %</span>
            <span>50 %</span>
            <span>100 %</span>
          </div>
        </div>
      </div>
      </div>
      {/* Kontejner pro informace */}
      <div className="bess-info-container">
        <div className="bess-info-grid">
          {/* Status BESS */}
          <div className="bess-info-item">
            <h4 className="bess-info-title">STATUS BESS</h4>
            <p className="bess-info-value">
              {mqttData.bessData?.Status_BESS !== undefined
                ? `${mqttData.bessData.Status_BESS}`
                : 'Načítám...'}
            </p>
          </div>

          {/* Výkon BESS */}
          <div className="bess-info-item">
            <h4 className="bess-info-title">VÝKON NABÍJENÍ</h4>
            <p className="bess-info-value">
              {mqttData.bessData?.Total_Power_BESS_Charge !== undefined
                ? `${mqttData.bessData.Total_Power_BESS_Charge} kW`
                : 'Načítám...'}
            </p>
          </div>

          {/* Výkon vybíjení */}
          <div className="bess-info-item">
            <h4 className="bess-info-title">VÝKON VYBÍJENÍ</h4>
            <p className="bess-info-value">
              {mqttData.bessData?.Total_Power_BESS_Discharge !== undefined
                ? `${-mqttData.bessData.Total_Power_BESS_Discharge} kW`
                : 'Načítám...'}
            </p>
          </div>

          {/* Nový Informační Box: Vlastní spotřeba */}
          <div className="bess-info-item">
            <h4 className="bess-info-title">VLASTNÍ SPOTŘEBA</h4>
            <p className="bess-info-value">
              {mqttData.bessData?.OwnConsumption !== undefined
                ? `${mqttData.bessData.OwnConsumption} kW`
                : 'Načítám...'}
            </p>
          </div>
        </div>
      </div>

      {/* Kontejner pro tabulku */}
      <div className="bess-overview-wrapper">
        <div className="bess-overview-bar">
          <h2>BATERIOVÝ SYSTÉM</h2>
        </div>
        <div className="bess-overview-img">
          <img src="/images/BESS.png" alt="Bateriový systém" />
        </div>

        <div className="bess-table-container">
          {/* Tabulka obsahu */}
          <div className="bess-grid-item">
            {/* Aktuální přehled */}
            <div className="bess-sub-section">
              <h3>Měnič bateriového systému</h3>
              <p>
                <span>Výkon měniče L1:</span>
                <span>
                  {mqttData.bessData?.Power_BESS_L1 !== undefined
                    ? `${mqttData.bessData.Power_BESS_L1} kW`
                    : 'Načítám...'}
                </span>
              </p>
              <p>
                <span>Výkon měniče L2:</span>
                <span>
                  {mqttData.bessData?.Power_BESS_L2 !== undefined
                    ? `${mqttData.bessData.Power_BESS_L2} kW`
                    : 'Načítám...'}
                </span>
              </p>
              <p>
                <span>Výkon měniče L3:</span>
                <span>
                  {mqttData.bessData?.Power_BESS_L3 !== undefined
                    ? `${mqttData.bessData.Power_BESS_L3} kW`
                    : 'Načítám...'}
                </span>
              </p>
              <p>
                <span>Celkový výkon měniče:</span>
                <span>
                  {mqttData.bessData?.P_Sum_BESS !== undefined
                    ? `${mqttData.bessData.P_Sum_BESS} kW`
                    : 'Načítám...'}
                </span>
              </p>
              <p>
                <span>Jalový výkon měniče:</span>
                <span>
                  {mqttData.bessData?.QSum_BESS !== undefined
                    ? `${mqttData.bessData.QSum_BESS} kVAR`
                    : 'Načítám...'}
                </span>
              </p>
              <p>
                <span>Proud měniče – fáze L1:</span>
                <span>
                  {mqttData.bessData?.I_L1_BESS !== undefined
                    ? `${mqttData.bessData.I_L1_BESS} A`
                    : 'Načítám...'}
                </span>
              </p>
              <p>
                <span>Proud měniče – fáze L2:</span>
                <span>
                  {mqttData.bessData?.I_L2_BESS !== undefined
                    ? `${mqttData.bessData.I_L2_BESS} A`
                    : 'Načítám...'}
                </span>
              </p>
              <p>
                <span>Proud měniče – fáze L3:</span>
                <span>
                  {mqttData.bessData?.I_L3_BESS !== undefined
                    ? `${mqttData.bessData.I_L3_BESS} A`
                    : 'Načítám...'}
                </span>
              </p>
            </div>

            {/* Cykly BESS a Bateriové uložiště */}
            <div className="bess-sub-section-group">
              <div className="bess-sub-section">
                <h3>Cykly bateriového systému</h3>
                <p>
                  <span>Počet cyklů - dnes:</span>
                  <span>
                    {mqttData.BESSCyclesData?.Daily_Cycles !== undefined
                      ? `${mqttData.BESSCyclesData.Daily_Cycles}`
                      : 'Načítám...'}
                  </span>
                </p>
                <p>
                  <span>Počet cyklů - celkem:</span>
                  <span>
                    {mqttData.BESSCyclesData?.Cycles_Sum_Months !== undefined
                      ? `${mqttData.BESSCyclesData.Cycles_Sum_Months}`
                      : 'Načítám...'}
                  </span>
                </p>
                <p>
                  <span>Zbývající počet cyklů (Záruka):</span>
                  <span>
                    {mqttData.BESSCyclesData?.Cycles_Varranty !== undefined
                      ? `${mqttData.BESSCyclesData.Cycles_Varranty}`
                      : 'Načítám...'}
                  </span>
                </p>
              </div>

              <div className="bess-sub-section">
                <h3>Bateriové uložiště</h3>
                <p>
                  <span>Napětí článků (Součet):</span>
                  <span>
                    {mqttData.bessData?.U_BMS_BESS !== undefined
                      ? `${mqttData.bessData.U_BMS_BESS} V`
                      : 'Načítám...'}
                  </span>
                </p>
                <p>
                  <span>Proud článků:</span>
                  <span>
                    {mqttData.bessData?.I_BMS_BESS !== undefined
                      ? `${mqttData.bessData.I_BMS_BESS} A`
                      : 'Načítám...'}
                  </span>
                </p>
                <p>
                  <span>Výkon článků:</span>
                  <span>
                    {mqttData.bessData?.P_BMS_BESS !== undefined
                      ? `${mqttData.bessData.P_BMS_BESS} kW`
                      : 'Načítám...'}
                  </span>
                </p>
                <p>
                  <span>Nabití baterie:</span>
                  <span>
                    {mqttData.bessData?.SoC !== undefined
                      ? `${mqttData.bessData.SoC} %`
                      : 'Načítám...'}
                  </span>
                </p>
                <p>
                  <span>Teplota baterie:</span>
                  <span>
                    {mqttData.bessData?.AVG_BMS_Temperature !== undefined
                      ? `${mqttData.bessData.AVG_BMS_Temperature} °C`
                      : 'Načítám...'}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
