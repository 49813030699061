import React from 'react';
import './Menu.css';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation pro kontrolu aktivní stránky

function Menu() {
  const location = useLocation(); // Použijeme pro zjištění aktuální URL

  return (
    <nav className="side-nav">
      <div className="menu-header">
        <span className="menu-icon">☰</span> MENU
      </div>
      <div className="menu-divider"></div>

      <ul>
      <li className={location.pathname === "/" ? "active" : ""}>
          <img src={location.pathname === "/" ? "/images/Ikona_menu_oznaceni.png" : "/images/Ikona_menu_bezoznaceni.png"} alt="icon" className="menu-icon-item" />
          <Link to="/">Úvod</Link>
        </li>
        <li className={location.pathname === "/energy-flow" ? "active" : ""}>
          <img src={location.pathname === "/energy-flow" ? "/images/Ikona_menu_oznaceni.png" : "/images/Ikona_menu_bezoznaceni.png"} alt="icon" className="menu-icon-item" />
          <Link to="/energy-flow">Energetické toky</Link>
        </li>
        <li className={location.pathname === "/overview" ? "active" : ""}>
          <img src={location.pathname === "/overview" ? "/images/Ikona_menu_oznaceni.png" : "/images/Ikona_menu_bezoznaceni.png"} alt="icon" className="menu-icon-item" />
          <Link to="/overview">Základní přehled</Link>
        </li>
        <li className={location.pathname === "/fve" ? "active" : ""}>
          <img src={location.pathname === "/fve" ? "/images/Ikona_menu_oznaceni.png" : "/images/Ikona_menu_bezoznaceni.png"} alt="icon" className="menu-icon-item" />
          <Link to="/fve">Přehled FVE</Link>
        </li>
        <li className={location.pathname === "/bess" ? "active" : ""}>
          <img src={location.pathname === "/bess" ? "/images/Ikona_menu_oznaceni.png" : "/images/Ikona_menu_bezoznaceni.png"} alt="icon" className="menu-icon-item" />
          <Link to="/bess">Přehled BESS</Link>
        </li>
        <li className={location.pathname === "/electricity" ? "active" : ""}>
          <img src={location.pathname === "/electricity" ? "/images/Ikona_menu_oznaceni.png" : "/images/Ikona_menu_bezoznaceni.png"} alt="icon" className="menu-icon-item" />
          <Link to="/electricity">Měřící body instalace</Link>
        </li>
        <li className={location.pathname === "/spot-market" ? "active" : ""}>
          <img src={location.pathname === "/spot-market" ? "/images/Ikona_menu_oznaceni.png" : "/images/Ikona_menu_bezoznaceni.png"} alt="icon" className="menu-icon-item" />
          <Link to="/spot-market">Spotový trh</Link>
        </li>
        <li className={location.pathname === "/prediction" ? "active" : ""}>
          <img src={location.pathname === "/prediction" ? "/images/Ikona_menu_oznaceni.png" : "/images/Ikona_menu_bezoznaceni.png"} alt="icon" className="menu-icon-item" />
          <Link to="/prediction">Predikce výroby</Link>
        </li>
        <li className={location.pathname === "/dashboards" ? "active" : ""}>
          <img src={location.pathname === "/dashboards" ? "/images/Ikona_menu_oznaceni.png" : "/images/Ikona_menu_bezoznaceni.png"} alt="icon" className="menu-icon-item" />
          <Link to="/dashboards">Dashboardy</Link>
        </li>
        {/* 
        <li className={location.pathname === "/economics" ? "active" : ""}>
          <img src={location.pathname === "/economics" ? "/images/Ikona_menu_oznaceni.png" : "/images/Ikona_menu_bezoznaceni.png"} alt="icon" className="menu-icon-item" />
          <Link to="/economics">Ekonomika</Link>
        </li>
        */}
        {/* 
        <li className={location.pathname === "/alerts" ? "active" : ""}>
          <img src={location.pathname === "/alerts" ? "/images/Ikona_menu_oznaceni.png" : "/images/Ikona_menu_bezoznaceni.png"} alt="icon" className="menu-icon-item" />
          <Link to="/alerts">Alerty</Link>
        </li>
        */}
        
        <li className={location.pathname === "/reports" ? "active" : ""}>
          <img src={location.pathname === "/reports" ? "/images/Ikona_menu_oznaceni.png" : "/images/Ikona_menu_bezoznaceni.png"} alt="icon" className="menu-icon-item" />
          <Link to="/reports">Reporty</Link>
        </li>
        
        {/*<li className={location.pathname === "/test" ? "active" : ""}>
          <img src={location.pathname === "/test" ? "/images/Ikona_menu_oznaceni.png" : "/images/Ikona_menu_bezoznaceni.png"} alt="icon" className="menu-icon-item" />
          <Link to="/test">Test</Link>
        </li>*/}
      </ul>
    </nav>
  );
}

export default Menu;
